import { Box, Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";

const conatiner = {
  backgroundColor: "#141414",
  width: "100vw",
  minHeight: "100vh",
  color: "#fff",
  display: "flex",
  justifyContent: "center",
};

const ViewAll = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [playVideo, setPlayVideo] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState("");

  useEffect(() => {
    const allVideo = async () => {
      try {
        const url = "https://tutorialgames.co/api/category/view-all";
        const response = await axios.post(url, {
          category_id: location.state.category_id,
        });
        const { success, data } = response.data;
        success && setData(data);
      } catch (error) {
        console.error("Error While Fetching All Vidoe", error);
      }
    };

    allVideo();
  }, []);

  const playCurrentVideo = (url) => {
    setCurrentVideoUrl(url);
    setPlayVideo(true);
  };

  return (
    <>
      <Box component={"section"} sx={conatiner}>
        <Box width={"85%"} sx={{ position: "relative" }}>
          {/* Video Player Box */}
          {playVideo && (
            <Box
              mt={4}
              width={{ md: "50%", sm: "80%", xs: "90%" }}
              sx={{
                position: "fixed",
                zIndex: 999,
                top: "40%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <button
                onClick={() => setPlayVideo(false)}
                style={{ cursor: "pointer", color: "red" }}
              >
                X
              </button>
              <ReactPlayer
                url={currentVideoUrl}
                playing={playVideo}
                controls={true}
                width={"100%"}
                height={"50%"}
              />
            </Box>
          )}
          <Typography variant="h4" mt={4}>
            {data.name}
          </Typography>
          <Grid container mt={4} spacing={3}>
            {data?.video?.map(
              ({ title, thumbnail_image: image, video_url, id }) => (
                <Grid item md={4} sm={4} xs={12} key={id}>
                  <Box sx={{ position: "relative" }}>
                    {/* Image Box */}
                    <Box>
                      <img
                        src={image}
                        alt={title}
                        width={"100%"}
                        height={"100%"}
                      />
                    </Box>
                    {/* Text */}
                    <Box
                      sx={{
                        position: "absolute",
                        left: "1rem",
                        bottom: "1rem",
                      }}
                    >
                      <Typography variant="subtitle1">{title}</Typography>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => playCurrentVideo(video_url)}
                      >
                        Play Now
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ViewAll;
