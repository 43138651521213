import { Box, Button, Grid, Typography } from "@mui/material";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const conatiner = {
  backgroundColor: "#141414",
  width: "100vw",
  minHeight: "100vh",
  color: "#fff",
  display: "flex",
  justifyContent: "center",
};

const Tutorials = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [data, setData] = useState([]);
  const [playVideo, setPlayVideo] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState("");

  useEffect(() => {
    const checkSize = () => {
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth);
      });
    };
    checkSize();
    return window.removeEventListener("resize", checkSize);
  }, [width]);

  // Call API
  useEffect(() => {
    const fetchTutorials = async () => {
      try {
        const url = "https://tutorialgames.co/api/video/list";
        const response = await axios.post(url);
        const { data, success } = response.data;
        success && setData(data);
      } catch (error) {
        console.error("Error while fetching Tutorials list", error);
      }
    };

    fetchTutorials();
  }, []);

  const playCurrentVideo = (url) => {
    setCurrentVideoUrl(url);
    setPlayVideo(true);
  };

  return (
    <>
      <Box sx={conatiner} component={"section"}>
        <Box width={"85%"} sx={{ position: "relative" }}>
          {/* Video Player Box */}
          {playVideo && (
            <Box
            mt={4}
            width={{ md: "50%", sm: "80%", xs: "90%" }}
            sx={{
              position: "fixed",
              zIndex: 999,
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <button
              onClick={() => setPlayVideo(false)}
              style={{ cursor: "pointer", color: "red" }}
            >
              X
            </button>
              <ReactPlayer
                url={currentVideoUrl}
                playing={playVideo}
                controls={true}
                width={"100%"}
                height={"50%"}
              />
            </Box>
          )}
          {/* Video Player Setup */}
          {/* Page Heading */}
          <Typography variant="h4" mt={4} mb={5}>
            Tutorials
          </Typography>
          {/* Video Categories */}
          {data?.map(({ name, video: videos, id }, index) => (
            <Box key={++index}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" mt={2} mb={2}>
                  {name}
                </Typography>
                <Typography variant="subtitle1">
                  <Link
                    style={{ color: "red" }}
                    to="/view-all"
                    state={{ category_id: id }}
                  >
                    View All..
                  </Link>
                </Typography>
              </Box>
              {/* Video Row Start */}
              <Box sx={{ width: "100%" }} mb={5}>
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={50}
                  slidesPerView={width > 600 ? 3 : 1}
                  navigation
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                >
                  {videos?.map(
                    ({ title, thumbnail_image: image, video_url }) => (
                      <SwiperSlide key={video_url}>
                        <Box sx={{ position: "relative" }}>
                          {/* Image Box */}
                          <Box>
                            <img
                              src={image}
                              alt={title}
                              width={"100%"}
                              height={"100%"}
                            />
                          </Box>
                          {/* Text */}
                          <Box
                            sx={{
                              position: "absolute",
                              left: "1rem",
                              bottom: "1rem",
                            }}
                          >
                            <Typography variant="subtitle1">{title}</Typography>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => playCurrentVideo(video_url)}
                            >
                              Play Now
                            </Button>
                          </Box>
                        </Box>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </Box>
              {/* Video Row End */}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Tutorials;
