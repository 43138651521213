import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Tutorials from "./components/Tutorials"
import ViewAll from './components/ViewAll';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Tutorials />} />
          <Route path="view-all" element={<ViewAll />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
